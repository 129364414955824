import request from '@/plugins/axios'

// S 订单

// 订单列表其他列表参数
export const apiOtherLists = () => request.get('/visa.order/otherLists')
// 订单列表其他列表参数
export const getCateMark = () => request.get('/visa.order/getCateMark')

// 订单详情
export const orderInfoApi = (params: any) => request.get('/visa.order/getInfo', { params })

// 订单列表
export const apiOrderLists = (params: any) => request.get('/visa.order/lists', { params })


// 新增订单
export const createOrder = (params: any) => request.post('/visa.order/create', { params })

// 保存订单
export const saveOrderApi = (params: any) => request.post('/visa.order/saveOrder', { params })

// 审核
export const updateAudit = (params: any) => request.post('/visa.order/audit', { params })

// 修改
export const editOrderApi = (params: any) => request.post('/visa.order/editOrder', { params })

// 修改图片类型
export const editImgTypeApi = (params: any) => request.post('/visa.order/editImgType', { params })

// 保存图片
export const uploadImg = (params: any) => request.post('/Upload/upload_image', params)

// 精修
export const jingxiuImg = (params: any) => request.post('/visa.order/imageClassify', params)

// 图片识别
export const analyzeImage = (params: any) => request.post('/visa.order/analyzeImage', params)
